import React, { useState, useEffect } from 'react';
import AdminHeader from './AdminHeader';
import AdminSideBar from './AdminSideBar';

const AdminDashboard = () => {
    // State to store fetched events
    const [events, setEvents] = useState([]);

    // Fetch events data from the backend API
    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const response = await fetch('http://localhost:5000/api/event'); // Update URL if necessary
                const data = await response.json();
                setEvents(data); // Store events data in state
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []); // Empty dependency array means this runs only once when the component mounts

    return (
        <>
            <AdminHeader />

            <div className="row card-group">
                <div className="col-lg-3 order-2 order-lg-1">
                    <AdminSideBar />
                </div>
                <div className="col-lg-9 order-1 order-lg-2">
                    <div className="container">
                        <div className="news-events pt-0">
                            <div className="container container--md news-events__container fade-in fade-in--active">
                                <div className="news-events__group">
                                    <h4 className="news-events__heading videos-border">
                                        RECENT EVENTS
                                    </h4>

                                    {events.length > 0 ? (
                                        events.map((event, index) => (
                                            <div className="news-events__item" key={index}>
                                                <div className="news-events__text">
                                                    {event.eventName.length > 1 && (
                                                        <h6 className="headline d-flex">
                                                            <a
                                                                className="link-alt"
                                                                href={event.ViewPoster}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                            >
                                                                {event.eventName}
                                                                &nbsp;&nbsp;
                                                            </a>
                                                            <img
                                                                className="animatedImg"
                                                                src="../assets/images/media/NewAnimated.gif"
                                                                alt="image"
                                                            />
                                                        </h6>
                                                    )}
                                                    {event.eventDate.length > 1 && (
                                                        <small className="text-red">
                                                            {event.eventDate}
                                                        </small>
                                                    )}
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <p>No events available.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDashboard;
